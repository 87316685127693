import React from "react";
import { Spin } from "antd";

const fullScreenOverlay = {
  position: "fixed" as any,
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
};

const RcsLoading: React.FC = () => (
  <div style={fullScreenOverlay}>
    <Spin style={{ margin: "200px auto" }} size="large" />
  </div>
);
export default RcsLoading;
