export type Response<T> = {
  result?: T;
  error?: any;
};

export const post = async (
  endpoint: string,
  body: any
): Promise<Response<any>> => {
  return { error: "Not implemented" };
};

export const del = async (
  endpoint: string,
  params: any
): Promise<Response<any>> => {
  return { error: "Not implemented" };
};

export const postConfig = async (body: {
  key: string;
  rc: string;
  value: any;
}): Promise<Response<any>> => {
  return { error: "Not implemented" };
};

export const postAdmin = async (body: {
  email: string;
  rc: string;
}): Promise<Response<any>> => {
  return { error: "Not implemented" };
};

export const getAdminsByEmail = async (
  email: string
): Promise<Response<any>> => {
  return {
    result: [{ email: "demo@rave.tech", rc: "demo", rc_name: "Rave Demo" }],
  };
};

export const deleteAdmin = async (admin: {
  rc: string;
  email: string;
}): Promise<Response<any>> => {
  return { error: "Not implemented" };
};

export const getAdminsByRc = async (rc: string): Promise<Response<any>> => {
  throw new Error("Not implemented");
};

export const suggestAccount = async (
  organization: string,
  nameFirst: string,
  nameLast: string,
  email: string,
  phone: number
): Promise<Response<any>> => {
  return {
    result: [
      {
        organization: "Rave Tech",
        nameFirst: "John",
        nameLast: "Doe",
        email: "demo@rave.tech",
        phone: 12345678,
      },
      {
        organization: "Pee Paw",
        nameFirst: "Thiha",
        nameLast: "Tun",
        email: "alt.332@gmail.com",
        phone: 8462858,
      },
      {
        organization: "Tesla",
        nameFirst: "Elon",
        nameLast: "Musk",
        email: "elon.musk@tesla.com",
        phone: 65473864,
      },
    ],
  };
};

export const getRcInfo = async (rc: string): Promise<Response<any>> => {
  return {
    result: {
      name: "Demo Organisation",
      config: {
        tracking1: {
          label: "Branch",
          options: "a, BranchA\nb, BranchB\nc, BranchC",
        },
        tracking2: {
          label: "Branch",
          options: "a, BranchA\nb, BranchB\nc, BranchC",
        },
      },
      taxes: [
        {
          code: "",
          rate: "0",
          name: "No Tax",
        },
        {
          code: "sg-gst-standard",
          rate: "8",
          name: "GST Standard Rated",
        },
        {
          code: "sg-gst-zero",
          rate: "0",
          name: "GST Zero Rated",
        },
        {
          code: "sg-gst-exempt",
          rate: "0",
          name: "GST Exempted",
        },
      ],
    },
  };
};

export const getAllProducts = async (rc: string): Promise<Response<any>> => {
  return {
    result: {
      productGroups: [
        { code: "BATAMGOLF", name: "Batam Golf" },
        { code: "TICKETS", name: "Attration Tickets" },
      ],
      products: [
        {
          groupCode: "BATAMGOLF",
          code: "COURSE1-18HOLES",
          type: "golf-flight",
          name: "Course 1 - Full 18 Holes",
          amountUnit: "200",
          availabilityDatesInclude: "2023-04-01\n2023-07-01\n2023-12-01",
          availabilityTimings: "8:50\n9:00\n9:10",
        },
        {
          groupCode: "BATAMGOLF",
          name: "Course 2 - First 9 Holes",
          type: "golf-flight",
          code: "COURSE2-FIRSTHALF",
          amountUnit: "100",
          availabilityDatesInclude: "2023-04-01\n2023-07-01\n2023-12-01",
          availabilityTimings: "15:50\n16:00\n16:10",
        },
        {
          groupCode: "TICKETS",
          code: "USS",
          type: "generic-delivered",
          name: "Universal Studio Singapore",
          amountUnit: "80",
        },
        {
          groupCode: "TICKETS",
          code: "ZOO",
          type: "generic-delivered",
          name: "Singapore Zoo",
          amountUnit: "30",
        },
      ],
    },
  };
};

export const ping = async (): Promise<Response<any>> => {
  return { result: "OK" };
};
