import { Breadcrumb, Layout, Skeleton } from "antd";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { postAdmin, getAdminsByRc, deleteAdmin } from "../clientMock";
import AdminsTable from "./Admins/Table";
import AdminForm from "./Admins/Form";
import { useRouter } from "../util";

function Admins({ router }: { router: any }) {
  const { user, rc, breadcrumbs } = useContext(AppContext);
  const [admins, setAdmins] = useState<any>({ result: [] });
  const { isActive, isDefault, setRoute } = useRouter(router);

  const refreshAdmins = async () => {
    if (rc == null) {
      setAdmins({ result: [] });
    } else {
      setAdmins({ loading: true });
      const result = await getAdminsByRc(rc);
      setAdmins(result);
    }
  };

  const onNewAdmin = async (admin: any) => {
    if (rc == null) throw new Error("No rc selected");
    setRoute("", true);
    setAdmins({ loading: true });
    admin.rc = rc;
    await postAdmin(admin);
    await refreshAdmins();
  };

  const onDeleteAdmin = async (email: any) => {
    if (rc == null) throw new Error("No rc selected");
    setAdmins({ loading: true });
    const admin = { rc, email };
    await deleteAdmin(admin);
    await refreshAdmins();
  };

  useEffect(() => {
    (async () => {
      console.log("useeffect");
      refreshAdmins();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rc]);

  if (rc == null) return <div>Rc must be selected</div>;

  return (
    <Layout className="rave">
      <Breadcrumb items={breadcrumbs} />
      {isActive(["new", "New admin"]) && <AdminForm onNewAdmin={onNewAdmin} />}
      {isDefault() && (
        <Skeleton loading={admins?.loading === true} active={true}>
          <AdminsTable
            onDeleteAdmin={onDeleteAdmin}
            admins={admins}
            user={user}
          />
        </Skeleton>
      )}
    </Layout>
  );
}

export default Admins;
