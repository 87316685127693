import { Layout } from "antd";
const { Content } = Layout;

function Test() {
  //const { user } = useContext(AppContext);

  return (
    <Layout>
      <Content>
        <h1>Not found</h1>
      </Content>
    </Layout>
  );
}

export default Test;
