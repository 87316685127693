import { Button, Col, DatePicker, Layout, Row, Select, theme } from "antd";
import { Form, Input, AutoComplete } from "antd";
import { validateMessages } from "../../util";
import { useState, useRef, ChangeEventHandler } from "react";
import type { ProColumns } from "@ant-design/pro-components";
import { EditableProTable } from "@ant-design/pro-components";
import { BreadCrumbHeader, SubHeader } from "../../components/Headers";
import { suggestAccount } from "../../clientMock";
const { Content } = Layout;

//items table
type DataSourceType = {
  id: React.Key;
  description?: string;
  qty?: string;
  price?: string;
  taxRate?: string;
};

type MockDataType = {
  organization: string;
  nameFirst: string;
  nameLast: string;
  email: string;
  phone: number;
};

const defaultData: DataSourceType[] = [
  {
    id: Date.now().toString(),
    description: "",
    qty: ``,
    price: "",
    taxRate: undefined,
  },
];

const AutoCompleteInput = ({
  options,
  onSelect,
  onChange,
  name,
  label,
}: {
  options: MockDataType[];
  onSelect: any;
  onChange: ChangeEventHandler<HTMLInputElement>;
  name: string;
  label: string;
}) => (
  <Form.Item name={name} label={label}>
    <AutoComplete
      dropdownMatchSelectWidth={800}
      options={options}
      onSelect={onSelect}
      autoClearSearchValue
    >
      <Input placeholder="input here" onChange={onChange} />
    </AutoComplete>
  </Form.Item>
);

function QuoteForm() {
  let debounceTimeout: NodeJS.Timeout | undefined;
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [form] = Form.useForm();

  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>(() =>
    defaultData.map((item) => item.id)
  );
  const [dataSource, setDataSource] = useState<readonly DataSourceType[]>(
    () => defaultData
  );
  const [suggestions, setSuggestions] = useState<MockDataType[]>([]);
  const autoCompleteData = useRef<MockDataType[]>([]);

  // get rcInfo from context
  // const { rcInfo } = useContext(AppContext);

  // tracking1 and tracking 2
  // rcInfo.result.config.tracking1
  // rcInfo.result.config.tracking2

  // taxes
  // rcInfo.result.taxes

  // autocomplete of email/phone/name
  // suggestAccount

  // get all products
  // getAllProducts()

  const userDataAutoComplete = ({
    query,
    type,
  }: {
    query: string;
    type: string;
  }) => {
    clearTimeout(debounceTimeout);

    if (!query) {
      autoCompleteData.current = [];
      setSuggestions([]);
      return;
    }

    if (query.length > 2) {
      debounceTimeout = setTimeout(async () => {
        const { result }: any = await suggestAccount("", "", "", "", 0);

        autoCompleteData.current = [...result];

        setSuggestions(
          result.map((x: MockDataType, index: number) => ({
            value: `${index}`,
            label: (
              <div>
                <div className="quote-autocomplete-dropdown">
                  {`${x.nameFirst} ${x.nameLast}`}
                </div>
                <div className="quote-autocomplete-dropdown">
                  {x.organization}
                </div>
                <div className="quote-autocomplete-dropdown">{x.email}</div>
                <div className="quote-autocomplete-dropdown">{x.phone}</div>
              </div>
            ),
          }))
        );
      }, 500);
    }
  };

  const autoFillForm = (dataIndex: number) => {
    const selectedData: MockDataType = autoCompleteData.current[dataIndex];

    console.log("hi");

    form.setFieldsValue({
      nameFirst: selectedData.nameFirst,
      nameLast: selectedData.nameLast,
      email: selectedData.email,
      organization: selectedData.organization,
      phone: selectedData.phone,
    });
  };

  const columns: ProColumns<DataSourceType>[] = [
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      width: "30%",
    },
    {
      title: "Qty",
      key: "qty",
      dataIndex: "qty",
    },
    {
      title: "Unit Price",
      key: "price",
      dataIndex: "price",
    },
    {
      title: "Tax Code",
      key: "taxCode",
      dataIndex: "taxCode",
      valueType: "select",
      valueEnum: {
        "sg-gst-standard": {
          text: "Standard-Rated Supplies (8%)",
        },
        "sg-gst-zero": {
          text: "Zero-Rated Supplies (0%)",
        },
        "sg-gst-exempt": {
          text: "Exempt Supplies (0%)",
        },
      },
    },
    {
      title: "Tax%",
      key: "taxRate",
      dataIndex: "taxRate",
    },
  ];

  return (
    <>
      <BreadCrumbHeader>
        <Button
          type="primary"
          onClick={() => {
            // this is the desired data structure
            console.log({
              account: {
                email: "",
                phone: "",
                nameDisplay: "",
                nameFirst: "",
                nameLast: "",
                organization: "",
              },
              transcationGroup: {
                dateExpiry: 0,
                dateDue: 0,
                trackingCode1: "",
                trackingCode2: "",
              },

              booking: [
                {
                  productGroup: "",
                  productCode: "",
                  productType: "",
                  form: {
                    date: 0,
                    timing: "",
                  },
                  transcations: [
                    {
                      description: "",
                      amountUnit: "",
                      amountTQuantity: 0,
                      taxCode: "",
                      taxRate: "",
                    },
                  ],
                },
              ],
            });
          }}
        >
          Save
        </Button>
      </BreadCrumbHeader>
      <Content
        style={{
          flex: "none",
          background: colorBgContainer,
        }}
      >
        <Form layout="vertical" form={form} validateMessages={validateMessages}>
          <Row gutter={8}>
            <Col span={6}>
              <AutoCompleteInput
                options={suggestions}
                onSelect={(e: any) => autoFillForm(e)}
                onChange={(e: any) =>
                  userDataAutoComplete({
                    query: e.target.value,
                    type: "email",
                  })
                }
                name="email"
                label="Email"
              />
            </Col>
            <Col span={6}>
              <AutoCompleteInput
                options={suggestions}
                onSelect={(e: any) => autoFillForm(e)}
                onChange={(e: any) =>
                  userDataAutoComplete({
                    query: e.target.value,
                    type: "phone",
                  })
                }
                name="phone"
                label="Phone"
              />
            </Col>
            <Col span={4}>
              <AutoCompleteInput
                options={suggestions}
                onSelect={(e: any) => autoFillForm(e)}
                onChange={(e: any) =>
                  userDataAutoComplete({
                    query: e.target.value,
                    type: "organization",
                  })
                }
                name="organization"
                label="Organization"
              />
            </Col>
            <Col span={4}>
              <AutoCompleteInput
                options={suggestions}
                onSelect={(e: any) => autoFillForm(e)}
                onChange={(e: any) =>
                  userDataAutoComplete({
                    query: e.target.value,
                    type: "nameFirst",
                  })
                }
                name="nameFirst"
                label="First Name"
              />
            </Col>
            <Col span={4}>
              <AutoCompleteInput
                options={suggestions}
                onSelect={(e: any) => autoFillForm(e)}
                onChange={(e: any) =>
                  userDataAutoComplete({
                    query: e.target.value,
                    type: "nameLast",
                  })
                }
                name="nameLast"
                label="Last Name"
              />
            </Col>
          </Row>

          <Row gutter={8}>
            <Col span={6}>
              <Form.Item name="dateExpiry" label="Expiry date">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="dateDue" label="Due date">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item name="tracking1" label="Branch">
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  options={[
                    { value: "1", label: "Branch 1" },
                    { value: "2", label: "Branch 2" },
                    { value: "3", label: "Branch 3" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="tracking2" label="UTM">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>

      <SubHeader title="Product 1"></SubHeader>
      <Content
        style={{
          flex: "none",
          background: colorBgContainer,
        }}
      >
        <Form layout="vertical">
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item name="productGroup" label="Product Group">
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="productCode" label="Product Code">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="productType" label="Product Type">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={4}>
              <Form.Item name="bookingDate" label="Booking Date">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item name="bookingTiming" label="Booking Timing">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <EditableProTable<DataSourceType>
            columns={columns}
            rowKey="id"
            style={{ width: "100%" }}
            scroll={{
              x: 960,
            }}
            value={dataSource}
            onChange={setDataSource}
            recordCreatorProps={{
              newRecordType: "dataSource",
              record: () => ({
                id: Date.now(),
              }),
            }}
            editable={{
              type: "multiple",
              editableKeys,
              actionRender: (row, config, defaultDoms) => {
                return [defaultDoms.delete];
              },
              onValuesChange: (record, recordList) => {
                setDataSource(recordList);
              },
              onChange: setEditableRowKeys,
            }}
          />
          <br />
          <Row gutter={8}>
            <Col offset={16} span={5}>
              <b>Total (Tax Inclusive)</b>
            </Col>
            <Col span={3}>$100.00</Col>
          </Row>
          <Row gutter={8}>
            <Col offset={16} span={5}>
              <b>GST Standard rated (8%)</b>
            </Col>
            <Col span={3}>$8.00</Col>
          </Row>
        </Form>
      </Content>
    </>
  );
}

export default QuoteForm;
