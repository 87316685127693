import { FormLayout } from "antd/es/form/Form";
import React from "react";

export const layout = {
  layout: "vertical" as FormLayout,
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
export const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
  },
};
/* eslint-enable no-template-curly-in-string */

var timeout: any;
export const useRouter = ({
  route,
  setRouteRoot, //for root
  setRoute, //for non root
  breadcrumbs,
  setBreadcrumbs,
  level = 0,
}: any): {
  isActive: any;
  isDefault: any;
  setRoute: any;
  childRouter: {
    route: string;
    setRoute: any;
    breadcrumbs: any[];
    level: number;
    setBreadcrumbs: any;
  };
} => {
  // init for root
  if (breadcrumbs == null) breadcrumbs = [];
  if (level == null) level = 0;
  if (setRouteRoot != null) {
    setRoute = (value: any, retain = false) => {
      if (!retain) setBreadcrumbs([]);
      setRouteRoot(value);
    };
  }

  var selectedRoute: string | null = null;
  const isActive = ([key, label]: [string, string]) => {
    const routeSplit = route.split("/");
    const result = key === routeSplit[0];
    if (result && selectedRoute == null) {
      selectedRoute = key;
      const currentBreadcrumb = breadcrumbs[level];
      const item = {
        title: React.createElement(
          "span",
          { onClick: () => setRoute(key, true) },
          label
        ),
        key,
      };

      // setTimeout is required to allow current component
      // to finish rendering before updating
      if (currentBreadcrumb == null) {
        breadcrumbs.push(item);
        if (timeout != null) clearTimeout(breadcrumbs);
        timeout = setTimeout(() => setBreadcrumbs([...breadcrumbs]), 50);
      } else if (currentBreadcrumb.key !== key) {
        breadcrumbs[level] = item;
        if (timeout != null) clearTimeout(breadcrumbs);
        timeout = setTimeout(() => setBreadcrumbs([...breadcrumbs]), 50);
      }
    }
    return result;
  };
  const isDefault = () => {
    return selectedRoute == null;
  };
  const childRoute = route.slice(route.indexOf("/") + 1);
  const childSetRoute = (value: string, clear: boolean = true) => {
    setRoute(selectedRoute + "/" + value, false);
  };

  return {
    isActive,
    isDefault,
    setRoute,
    childRouter: {
      route: childRoute,
      setRoute: childSetRoute,
      breadcrumbs,
      level: level + 1,
      setBreadcrumbs,
    },
  };
};
