import { Layout } from "antd";
import { useContext } from "react";
import { AppContext } from "../App";
import { useRouter } from "../util";
import Empty from "./Common/Empty";
import QuoteForm from "./Quotes/Form";

function Quotes({ router }: { router: any }) {
  const { rc } = useContext(AppContext);
  const { isActive, isDefault } = useRouter(router);

  if (rc == null) return <div>Rc must be selected</div>;

  return (
    <Layout className="rave">
      {isActive(["new", "New quote"]) && <QuoteForm />}
      {isDefault() && <Empty />}
    </Layout>
  );
}

export default Quotes;
