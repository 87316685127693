import { Button, Form, Input, Layout, Tabs, TabsProps, theme } from "antd";
import { useContext } from "react";
import { AppContext } from "../App";
import { BreadCrumbHeader } from "../components/Headers";
import { postConfig } from "../clientMock";

const { Content } = Layout;
const { TextArea } = Input;

const TrackingForm = (props: any) => (
  <Form layout="vertical" {...props}>
    <Form.Item label="Label" name="label">
      <Input />
    </Form.Item>

    <Form.Item label="Options" name="options">
      <TextArea rows={20} placeholder={"code1, label1\r\ncode2, label 2"} />
    </Form.Item>

    <Button type="primary" htmlType="submit">
      Save
    </Button>
  </Form>
);

function TrackingRoute({ router }: { router: any }) {
  const { rc, rcInfo, setRcInfo, messageApi } = useContext(AppContext);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  if (rc == null) return <div>Rc must be selected</div>;

  const onFinish = (key: string) => async (value: any) => {
    const result = await postConfig({ rc, key, value });
    const count = result?.result;
    if (count === 1) {
      rcInfo.result.config[key] = value;
      setRcInfo({ result: { ...rcInfo.result } });
      messageApi?.open({
        type: "success",
        content: "Config updated",
        duration: 10,
      });
    } else {
      messageApi?.open({
        type: "success",
        content: "Failed to update config",
        duration: 10,
      });
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "commercialTracking1",
      label: `Commercial Tracking 1`,
      children: (
        <TrackingForm
          onFinish={onFinish("commercialTracking1")}
          initialValues={rcInfo.result.config.commercialTracking1}
        />
      ),
    },
    {
      key: "commercialTracking2",
      label: `Commercial Tracking 2`,
      children: (
        <TrackingForm
          onFinish={onFinish("commercialTracking2")}
          initialValues={rcInfo.result.config.commercialTracking2}
        />
      ),
    },
    {
      key: "commercialTracking3",
      label: `Commercial Tracking 3`,
      children: (
        <TrackingForm
          onFinish={onFinish("commercialTracking3")}
          initialValues={rcInfo.result.config.commercialTracking2}
        />
      ),
    },
  ];

  return (
    <Layout className="rave">
      <BreadCrumbHeader></BreadCrumbHeader>

      <Content
        style={{
          flex: "none",
          background: colorBgContainer,
          paddingTop: 0,
        }}
      >
        <Tabs defaultActiveKey="1" items={items} />
      </Content>
    </Layout>
  );
}

export default TrackingRoute;
