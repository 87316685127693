import { Button, Layout, theme } from "antd";
import { useContext, useState } from "react";
import { AppContext } from "../App";
import { SubHeader } from "../components/Headers";
const { Content } = Layout;

function Test() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { user, rcInfo } = useContext(AppContext);
  const [output, setOutput] = useState<any>("");

  return (
    <Layout className="rave">
      <SubHeader title="Test page"></SubHeader>
      <Content
        style={{
          background: colorBgContainer,
        }}
      >
        <Button
          onClick={async () => {
            const id = await user?.getIdToken();
            setOutput(id);
            navigator.clipboard.writeText(id ?? "");
          }}
        >
          Get ID token
        </Button>
        <div>{output}</div>
        <pre>{JSON.stringify(rcInfo, null, 2)}</pre>
      </Content>
    </Layout>
  );
}

export default Test;
