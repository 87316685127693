import { Row, Col, Breadcrumb } from "antd";
import { AppContext } from "../App";
import { useContext } from "react";

export function BreadCrumbHeader({ children }: { children?: any }) {
  const { breadcrumbs } = useContext(AppContext);
  return (
    <Row className="route-nav">
      <Col span={18}>
        <Breadcrumb items={breadcrumbs} />
      </Col>
      <Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
        {children}
      </Col>
    </Row>
  );
}

export function SubHeader({
  title,
  children,
}: {
  title: string;
  children?: any;
}) {
  return (
    <Row className="route-nav" style={{ marginTop: 12 }}>
      <Col span={18} style={{ lineHeight: 5, fontSize: 18 }}>
        {title}
      </Col>
      <Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
        {children}
      </Col>
    </Row>
  );
}
