import { Layout } from "antd";
import { useContext } from "react";
import { AppContext } from "../App";
const { Content } = Layout;

function Index() {
  const { user } = useContext(AppContext);

  return (
    <Layout>
      <Content style={{ padding: "24px" }}>
        <h1>Welcome {user?.email}</h1>
      </Content>
    </Layout>
  );
}

export default Index;
