import { Layout } from "antd";

function NoRcSelected() {
  return (
    <Layout className="rave">
      <br />
      <br />
      <h1>Please select your company on the left navigation bar.</h1>
    </Layout>
  );
}

export default NoRcSelected;
