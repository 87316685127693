import { Layout, Skeleton, theme } from "antd";
import { BreadCrumbHeader } from "../../components/Headers";
const { Content } = Layout;

function Empty() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <>
      <BreadCrumbHeader></BreadCrumbHeader>
      <Content
        style={{
          background: colorBgContainer,
        }}
      >
        <Skeleton loading={true} active={true}></Skeleton>
      </Content>
    </>
  );
}

export default Empty;
