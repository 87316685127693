import { useEffect, useState } from "react";
import {
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  User,
  createUserWithEmailAndPassword,
  getAuth,
  getMultiFactorResolver,
  multiFactor,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { Button, Form, Input, Tabs, TabsProps } from "antd";
import { validateMessages } from "../util";

var resolver: any;

export const Auth = ({
  user,
  setUser,
  secondFactorEnrolled,
  setSecondFactorEnrolled,
}: {
  user: User | null;
  setUser: any;
  secondFactorEnrolled: boolean;
  setSecondFactorEnrolled: any;
}) => {
  const [loading, setLoading] = useState(false);

  const [loginError, setLoginError] = useState("");
  const [validateLoginError, setValidateLoginError] = useState("");
  const [verificationIdForLogin, setVerificationIdForLogin] = useState<
    string | null
  >(null);

  const [resetPasswordError, setResetPasswordError] = useState("");
  const [passwordResetDone, setPasswordResetDone] = useState(false);

  const [signUpError, setSignUpError] = useState("");
  const [registerPhoneError, setRegisterPhoneError] = useState("");
  const [validatePhoneError, setValidatePhoneError] = useState("");
  const [verificationIdForEnrollment, setVerificationIdForEnrollment] =
    useState<string | null>(null);

  const errorCodeToMessage: { [key: string]: string } = {
    "email-already-in-use": "Email already in use",
    "valid-verification-code": "Verification code is not valid",
    "auth/invalid-login-credentials": "Invalid email or password",
  };

  const auth = getAuth();

  /*
        const refreshUserUntil = async (check: any) => {
          for (var x = 0; x < 10; x++) {
            await user?.reload()
            const newUser = auth.currentUser?.toJSON()
            console.log(newUser)
            if (check != null && check(newUser)) {
              return
            }
            sleep(1000)
          }
        }
        */

  const resetPassword = async (values: any) => {
    setLoading(true);
    setResetPasswordError("");
    try {
      await sendPasswordResetEmail(auth, values.email);
      setPasswordResetDone(true);
    } catch (error: any) {
      const errorCode: string = error.code;
      var message = errorCodeToMessage[errorCode];
      if (message == null) message = errorCode;
      setResetPasswordError(message);
    }
    setLoading(false);
  };

  const validateLogin = async (values: any) => {
    setLoading(true);
    setValidateLoginError("");
    try {
      if (verificationIdForLogin == null) throw new Error("No verification id");

      const cred = PhoneAuthProvider.credential(
        verificationIdForLogin,
        values.code
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      resolver.resolveSignIn(multiFactorAssertion);
    } catch (error: any) {
      const errorCode: string = error.code;
      var message = errorCodeToMessage[errorCode];
      if (message == null) message = errorCode;
      setValidateLoginError(message);
    }
    setLoading(false);
  };

  const validatePhoneNumber = async (values: any) => {
    setLoading(true);
    setValidatePhoneError("");
    try {
      if (verificationIdForEnrollment == null)
        throw new Error("No verification id");
      if (user == null) throw new Error("No user");

      const cred = PhoneAuthProvider.credential(
        verificationIdForEnrollment,
        values.code
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      await multiFactor(user).enroll(multiFactorAssertion);
    } catch (error: any) {
      const errorCode: string = error.code;
      var message = errorCodeToMessage[errorCode];
      if (message == null) message = errorCode;
      setValidatePhoneError(message);
    }
    setLoading(false);
  };

  const registerPhoneNumber = async (values: any) => {
    setLoading(true);
    setRegisterPhoneError("");
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      //warning: shadowing
      const user = userCredential.user;
      const multiFactorSession = await multiFactor(user).getSession();
      const recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",

        // Optional reCAPTCHA parameters.
        {
          size: "normal",
          callback: async function (response: any) {
            // reCAPTCHA solved
            const phoneAuthProvider = new PhoneAuthProvider(auth);
            const phoneInfoOptions = {
              phoneNumber: values.phone,
              session: multiFactorSession,
            };
            const verificationIdForEnrollment =
              await phoneAuthProvider.verifyPhoneNumber(
                phoneInfoOptions,
                recaptchaVerifier
              );
            setVerificationIdForEnrollment(verificationIdForEnrollment);
          },
          "expired-callback": function () {
            console.log("recaptcha expired");
            recaptchaVerifier.clear();
          },
        },
        auth
      );
      recaptchaVerifier.render();
    } catch (error: any) {
      const errorCode: string = error.code;
      var message = errorCodeToMessage[errorCode];
      if (message == null) message = errorCode;
      setRegisterPhoneError(message);
    }
    setLoading(false);
  };

  const signUp = async (values: any) => {
    setLoading(true);
    setSignUpError("");
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );
      sendEmailVerification(userCredential.user);
    } catch (error: any) {
      const errorCode: string = error.code;
      var message = errorCodeToMessage[errorCode];
      if (message == null) message = errorCode;
      setSignUpError(message);
    }
    setLoading(false);
  };

  //not yet implemented
  const login = async (values: any) => {
    setLoading(true);
    setLoginError("");
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
    } catch (error: any) {
      if (error.code === "auth/multi-factor-auth-required") {
        resolver = getMultiFactorResolver(auth, error);
        const recaptchaVerifier = new RecaptchaVerifier(
          "recaptcha-container2",

          // Optional reCAPTCHA parameters.
          {
            size: "normal",
            callback: async function (response: any) {
              console.log("callback");
              // reCAPTCHA solved
              const phoneAuthProvider = new PhoneAuthProvider(auth);
              const phoneInfoOptions = {
                multiFactorHint: resolver.hints[0],
                session: resolver.session,
              };
              const verificationIdForLogin =
                await phoneAuthProvider.verifyPhoneNumber(
                  phoneInfoOptions,
                  recaptchaVerifier
                );
              setVerificationIdForLogin(verificationIdForLogin);
            },
            "expired-callback": function () {
              console.log("expired");
              recaptchaVerifier.clear();
            },
          },
          auth
        );
        recaptchaVerifier.render();
      } else {
        const errorCode: string = error.code;
        var message = errorCodeToMessage[errorCode];
        if (message == null) message = errorCode;
        setLoginError(message);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (newUser) => {
      setUser(newUser);
      if (newUser != null) {
        const enrolledFactors = multiFactor(newUser).enrolledFactors;
        setSecondFactorEnrolled(enrolledFactors.length > 0);
      } else {
        setSecondFactorEnrolled(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Login`,
      children: (
        <div>
          {verificationIdForLogin == null && (
            <>
              <Form
                wrapperCol={{ span: 24 }}
                layout="vertical"
                onFinish={login}
                style={{ width: "100%" }}
                validateMessages={validateMessages}
              >
                <Form.Item
                  name={["email"]}
                  label="Email"
                  rules={[{ required: true, type: "email" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={["password"]}
                  label="Password"
                  rules={[{ required: true }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Login
                  </Button>
                  <div
                    style={{
                      display: "inline-block",
                      paddingLeft: "20px",
                      flex: 1,
                    }}
                    className="ant-form-item-explain-error"
                  >
                    {loginError}
                  </div>
                </Form.Item>
              </Form>
              <div id="recaptcha-container2"></div>
            </>
          )}

          {verificationIdForLogin != null && (
            <>
              <div>Please enter the verification code sent to your phone</div>
              <br />
              <Form
                wrapperCol={{ span: 24 }}
                layout="vertical"
                onFinish={validateLogin}
                style={{ width: "100%" }}
                validateMessages={validateMessages}
              >
                <Form.Item
                  name={["code"]}
                  label="Code"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Verify and Login
                  </Button>
                  <div
                    style={{
                      display: "inline-block",
                      paddingLeft: "20px",
                      flex: 1,
                    }}
                    className="ant-form-item-explain-error"
                  >
                    {validateLoginError}
                  </div>
                </Form.Item>
              </Form>
            </>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: `Reset Password`,
      children: (
        <>
          {passwordResetDone === false && (
            <Form
              wrapperCol={{ span: 24 }}
              layout="vertical"
              onFinish={resetPassword}
              style={{ width: "100%" }}
              validateMessages={validateMessages}
            >
              <Form.Item
                name={["email"]}
                label="Email"
                rules={[{ required: true, type: "email" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Reset Password
                </Button>
                <div
                  style={{
                    display: "inline-block",
                    paddingLeft: "20px",
                    flex: 1,
                  }}
                  className="ant-form-item-explain-error"
                >
                  {resetPasswordError}
                </div>
              </Form.Item>
            </Form>
          )}
          {passwordResetDone && (
            <div>Check your email for instructions to reset your password</div>
          )}
        </>
      ),
    },
    {
      key: "3",
      label: `Sign Up`,
      children: (
        <Form
          wrapperCol={{ span: 24 }}
          layout="vertical"
          onFinish={signUp}
          style={{ width: "100%" }}
          validateMessages={validateMessages}
        >
          <Form.Item
            name={["email"]}
            label="Email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={["password"]}
            label="Password"
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Sign Up
            </Button>
            <div
              style={{
                display: "inline-block",
                paddingLeft: "20px",
                flex: 1,
              }}
              className="ant-form-item-explain-error"
            >
              {signUpError}
            </div>
          </Form.Item>
        </Form>
      ),
    },
  ];

  return (
    <>
      {user == null && <Tabs defaultActiveKey="1" items={items} />}
      {user != null && user.emailVerified !== true && (
        <div>
          <div>
            Your email is not verified. Please check your email for the
            verification link and refresh the page after clicking on the link.
          </div>
          <Button
            type="link"
            onClick={async () => {
              user.reload();
            }}
          >
            Reload
          </Button>
          <Button type="link" onClick={() => auth.signOut()}>
            Sign Out
          </Button>
        </div>
      )}
      {user != null && user.emailVerified && secondFactorEnrolled === false && (
        <div>
          {verificationIdForEnrollment == null && (
            <>
              <div>
                For added security, we require your phone number as 2FA. An SMS
                will be sent to your phone number for validation.
              </div>
              <br />
              <Form
                wrapperCol={{ span: 24 }}
                layout="vertical"
                onFinish={registerPhoneNumber}
                style={{ width: "100%" }}
                validateMessages={validateMessages}
              >
                <Form.Item
                  name={["email"]}
                  label="Email"
                  rules={[{ required: true, type: "email" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={["password"]}
                  label="Password"
                  rules={[{ required: true }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name={["phone"]}
                  label="Phone"
                  rules={[
                    {
                      required: true,
                      pattern: /^\+[0-9]+$/,
                      message:
                        "Please enter a valid phone number with country code",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Register phone number
                  </Button>
                  <div
                    style={{
                      display: "inline-block",
                      paddingLeft: "20px",
                      flex: 1,
                    }}
                    className="ant-form-item-explain-error"
                  >
                    {registerPhoneError}
                  </div>
                </Form.Item>
              </Form>

              <div id="recaptcha-container"></div>
            </>
          )}
          {verificationIdForEnrollment != null && (
            <>
              <div>Please enter the verification code sent to your phone</div>
              <br />
              <Form
                wrapperCol={{ span: 24 }}
                layout="vertical"
                onFinish={validatePhoneNumber}
                style={{ width: "100%" }}
                validateMessages={validateMessages}
              >
                <Form.Item
                  name={["code"]}
                  label="Code"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Validate phone number
                  </Button>
                  <div
                    style={{
                      display: "inline-block",
                      paddingLeft: "20px",
                      flex: 1,
                    }}
                    className="ant-form-item-explain-error"
                  >
                    {validatePhoneError}
                  </div>
                </Form.Item>
              </Form>
            </>
          )}
          <Button
            type="link"
            onClick={async () => {
              user.reload();
            }}
          >
            Reload
          </Button>
          <Button type="link" onClick={() => auth.signOut()}>
            Sign Out
          </Button>
        </div>
      )}
    </>
  );
};
