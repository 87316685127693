import { Table } from "antd";
import { Space, Button } from "antd";
import { User } from "firebase/auth";

function AdminsTable({
  admins,
  user,
  onDeleteAdmin,
}: {
  admins: any;
  user: User | null;
  onDeleteAdmin: any;
}) {
  return (
    <>
      <Table
        dataSource={admins?.result?.map((row: any, key: number) => {
          row.key = key;
          return row;
        })}
        columns={[
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Action",
            key: "action",
            render: (_, record) => (
              <Space size="middle">
                <Button
                  disabled={
                    record.email.toLowerCase() === user?.email?.toLowerCase()
                  }
                  onClick={() => {
                    onDeleteAdmin(record.email);
                  }}
                >
                  Delete
                </Button>
              </Space>
            ),
          },
        ]}
      />
    </>
  );
}

export default AdminsTable;
