import { Button } from "antd";
import { getAuth } from "firebase/auth";

const fullScreenOverlay = {
  position: "fixed" as any,
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  display: "flex",
};

function NoRcAllowed() {
  //const { user } = useContext(AppContext);
  const auth = getAuth();

  return (
    <div style={fullScreenOverlay}>
      <div style={{ flexBasis: "500px", padding: "50px", textAlign: "center" }}>
        <h3>No Rcs authorized with your email</h3>
        <Button onClick={() => auth.signOut()}>Sign Out</Button>
      </div>
      <div style={{ flex: 1, backgroundColor: "#eee" }}></div>
    </div>
  );
}

export default NoRcAllowed;
