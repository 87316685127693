import { Form, Input, Button } from "antd";
import { layout, validateMessages } from "../../util";

function AdminForm({ onNewAdmin }: { onNewAdmin: any }) {
  const [form] = Form.useForm();

  return (
    <Form
      {...layout}
      form={form}
      onFinish={onNewAdmin}
      style={{ maxWidth: 600 }}
      validateMessages={validateMessages}
    >
      <Form.Item
        name="email"
        label="Email"
        rules={[{ required: true, type: "email" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button htmlType="submit" type="primary">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

export default AdminForm;
